import React from 'react';
import './TradingJournalEntry.scss';
import clsx from 'clsx';

export const TradeType = {
    BUY: "BUY",
    SELL: "SELL"
}

export const TradeResult = {
    WIN: "WIN",
    LOSS: "LOSS"
}

export interface ITradingJournalEntry {
    type: string,
    assetName: string,
    assetSymbol: string,
    datetime: string
    entry: number,
    stopLoss: number,
    takeProfit: number,
    riskToReward: number,
    result: string
}

export const TradingJournalEntry = (props: ITradingJournalEntry) => {
    return (
        <div className='TradingJournalEntry'>
            <div className='TradingJournalEntry__Image' />
            <div className='TradingJournalEntry__Info TradingJournalEntry__FirstInfoEntry'>
                <div className={clsx(
                    'TradingJournalEntry__Name',
                    { "TradingJournalEntry__Buy": props.type === TradeType.BUY },
                    { "TradingJournalEntry__Sell": props.type === TradeType.SELL }
                )}>{props.type}</div>
                <div className='TradingJournalEntry__Value'>{props.assetName}</div>
            </div>
            <div className='TradingJournalEntry__Info'>
                <div className='TradingJournalEntry__Name'>Asset Symbol</div>
                <div className='TradingJournalEntry__Value'>{props.assetSymbol}</div>
            </div>
            <div className='TradingJournalEntry__Info'>
                <div className='TradingJournalEntry__Name'>Datetime of entry</div>
                <div className='TradingJournalEntry__Value'>{props.datetime}</div>
            </div>
            <div className='TradingJournalEntry__Info'>
                <div className='TradingJournalEntry__Name'>Entry</div>
                <div className='TradingJournalEntry__Value'>{props.entry}</div>
            </div>
            <div className='TradingJournalEntry__Info'>
                <div className='TradingJournalEntry__Name'>Stop Loss</div>
                <div className='TradingJournalEntry__Value'>{props.stopLoss}</div>
            </div>
            <div className='TradingJournalEntry__Info'>
                <div className='TradingJournalEntry__Name'>Take Profit</div>
                <div className='TradingJournalEntry__Value'>{props.takeProfit}</div>
            </div>
            <div className='TradingJournalEntry__Info'>
                <div className='TradingJournalEntry__Name'>Risk to reward</div>
                <div className='TradingJournalEntry__Value'>{props.riskToReward}</div>
            </div>
            <div className='TradingJournalEntry__Info'>
                <div className='TradingJournalEntry__Name'>Result</div>
                <div className={clsx(
                    'TradingJournalEntry__Value',
                    { "TradingJournalEntry__Loss": props.result === TradeResult.LOSS },
                    { "TradingJournalEntry__Win": props.result === TradeResult.WIN }
                )}>{props.result}</div>
            </div>
        </div>
    );
};