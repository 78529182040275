import { Button, CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { TradeResult, TradeType, TradingJournalEntry } from './components/TradingJournalEntry/TradingJournalEntry';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <div></div>,
  },
  {
    path: "/journal",
    element: <div></div>,
  },
]);

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <RouterProvider router={router} />
      <div style={{ padding: '16px' }}>
        <div>
          <TradingJournalEntry
            type={TradeType.BUY}
            assetName='GOLD'
            assetSymbol='XAUUSD'
            entry={1.09000}
            stopLoss={1.08000}
            takeProfit={1.1000}
            riskToReward={2.2}
            result={TradeResult.WIN}
            datetime='12.12.2023 16:00'
          />
        </div>
        <div style={{ marginTop: "16px" }}>
          <TradingJournalEntry
            type={TradeType.SELL}
            assetName='EUR/USD'
            assetSymbol='EUR/USD'
            entry={1.09000}
            stopLoss={1.08000}
            takeProfit={1.1000}
            riskToReward={2.2}
            result={TradeResult.LOSS}
            datetime='12.12.2023 16:00'
          />
        </div>
        <div style={{ marginTop: "16px" }}>
          <TradingJournalEntry
            type={TradeType.BUY}
            assetName='Bitcoin'
            assetSymbol='BTCUSD'
            entry={1.09000}
            stopLoss={1.08000}
            takeProfit={1.1000}
            riskToReward={2.2}
            result={TradeResult.WIN}
            datetime='12.12.2023 16:00'
          />
        </div>
        <div style={{ marginTop: "16px" }}>
          <TradingJournalEntry
            type={TradeType.SELL}
            assetName='EUR/JPY'
            assetSymbol='EUR/JPY'
            entry={1.09000}
            stopLoss={1.08000}
            takeProfit={1.1000}
            riskToReward={2.2}
            result={TradeResult.LOSS}
            datetime='12.12.2023 16:00'
          />
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
